.tags-view-wrapper .tags-view-item .close-btn {
  width: 16px;
  height: 16px;
  display: inline-block;
  line-height: 14px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
}
.tags-view-wrapper .tags-view-item .close-btn:hover {
    background-color: #b4bccc;
    color: #fff;
}
