/* stylelint-disable */
/*fade*/
.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.28s;
  transition: opacity 0.28s; }

.fade-enter,
.fade-leave-active {
  opacity: 0; }

/*fade-transform*/
.fade-transform-leave-active,
.fade-transform-enter-active {
  -webkit-transition: all .3s;
  transition: all .3s; }

.fade-transform-enter {
  opacity: 0; }

.fade-transform-leave-to {
  opacity: 0; }

/*breadcrumb transition*/
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  -webkit-transition: all .3s;
  transition: all .3s; }

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  -webkit-transform: translateX(20px);
          transform: translateX(20px); }

.breadcrumb-move {
  -webkit-transition: all .5s;
  transition: all .5s; }

.breadcrumb-leave-active {
  position: absolute; }

#app .main-container {
  min-height: 100%;
  -webkit-transition: margin-left .28s;
  transition: margin-left .28s;
  margin-left: 210px;
  position: relative; }

#app .sidebar-container {
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
  width: 210px !important;
  background-color: #304156;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden; }
  #app .sidebar-container .horizontal-collapse-transition {
    -webkit-transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
    transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out; }
  #app .sidebar-container .scrollbar-wrapper {
    overflow-x: hidden !important; }
  #app .sidebar-container .el-scrollbar__bar.is-vertical {
    right: 0px; }
  #app .sidebar-container .el-scrollbar {
    height: 100%; }
  #app .sidebar-container.has-logo .el-scrollbar {
    height: calc(100% - 50px); }
  #app .sidebar-container .is-horizontal {
    display: none; }
  #app .sidebar-container a {
    display: inline-block;
    width: 100%;
    overflow: hidden; }
  #app .sidebar-container .menu-icon {
    margin-right: 12px;
    font-size: 18px; }
  #app .sidebar-container .el-menu {
    border: none;
    height: 100%;
    width: 100% !important; }
  #app .sidebar-container .submenu-title-noDropdown:hover,
  #app .sidebar-container .el-submenu__title:hover {
    background-color: #263445 !important; }
  #app .sidebar-container .is-active > .el-submenu__title {
    color: #f4f4f5 !important; }
  #app .sidebar-container .nest-menu .el-submenu > .el-submenu__title,
  #app .sidebar-container .el-submenu .el-menu-item {
    min-width: 210px !important;
    background-color: #1f2d3d !important; }
    #app .sidebar-container .nest-menu .el-submenu > .el-submenu__title:hover,
    #app .sidebar-container .el-submenu .el-menu-item:hover {
      background-color: #001528 !important; }

#app .hideSidebar .sidebar-container {
  width: 54px !important; }

#app .hideSidebar .main-container {
  margin-left: 54px; }

#app .hideSidebar .submenu-title-noDropdown {
  padding: 0 !important;
  position: relative; }
  #app .hideSidebar .submenu-title-noDropdown .el-tooltip {
    padding: 0 !important; }
    #app .hideSidebar .submenu-title-noDropdown .el-tooltip .menu-icon {
      margin-left: 20px; }

#app .hideSidebar .el-submenu {
  overflow: hidden; }
  #app .hideSidebar .el-submenu > .el-submenu__title {
    padding: 0 !important; }
    #app .hideSidebar .el-submenu > .el-submenu__title .menu-icon {
      margin-left: 20px; }
    #app .hideSidebar .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
      display: none; }

#app .hideSidebar .el-menu--collapse .el-submenu > .el-submenu__title > span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block; }

#app .el-menu--collapse .el-menu .el-submenu {
  min-width: 210px !important; }

#app .mobile .main-container {
  margin-left: 0px; }

#app .mobile .sidebar-container {
  -webkit-transition: -webkit-transform .28s;
  transition: -webkit-transform .28s;
  transition: transform .28s;
  transition: transform .28s, -webkit-transform .28s;
  width: 210px !important; }

#app .mobile.hideSidebar .sidebar-container {
  pointer-events: none;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transform: translate3d(-210px, 0, 0);
          transform: translate3d(-210px, 0, 0); }

#app .withoutAnimation .main-container,
#app .withoutAnimation .sidebar-container {
  -webkit-transition: none;
  transition: none; }

.el-menu--vertical > .el-menu .menu-icon {
  margin-right: 16px; }

.el-menu--vertical .nest-menu .el-submenu > .el-submenu__title:hover,
.el-menu--vertical .el-menu-item:hover {
  background-color: #263445 !important; }

.el-menu--vertical > .el-menu--popup {
  max-height: 100vh;
  overflow-y: auto; }
  .el-menu--vertical > .el-menu--popup::-webkit-scrollbar-track-piece {
    background: #d3dce6; }
  .el-menu--vertical > .el-menu--popup::-webkit-scrollbar {
    width: 6px; }
  .el-menu--vertical > .el-menu--popup::-webkit-scrollbar-thumb {
    background: #99a9bf;
    border-radius: 20px; }

.advance-search-wrapper {
  border-bottom: 1px solid #e6e6e6;
  padding: 12px; }
  .advance-search-wrapper .el-form-item {
    margin: 0; }
  .advance-search-wrapper .search-form-item {
    margin-bottom: 16px; }
  .advance-search-wrapper .el-select,
  .advance-search-wrapper .el-input,
  .advance-search-wrapper .el-date-editor {
    width: 100%; }
  .advance-search-wrapper .label,
  .advance-search-wrapper .dash {
    font-size: 14px;
    line-height: 40px;
    white-space: nowrap;
    color: #606266; }
  .advance-search-wrapper .label {
    text-align: right; }
  .advance-search-wrapper .dash {
    text-align: center; }

.advance-search-operation-group {
  text-align: right; }

.main-table-wrapper {
  padding: 12px; }

.operation-row {
  margin: 8px 0; }
  .operation-row .el-button {
    margin-right: 8px; }

.edit-form {
  padding: 0 20px; }
  .edit-form .footer-button-row .el-button {
    float: right;
    margin-left: 20px; }
  .edit-form .el-select {
    width: 100%; }
  .edit-form .input-tip {
    font-size: 12px;
    line-height: 18px;
    color: #666; }

.help-message {
  font-size: 12px;
  color: #666;
  line-height: 12px;
  margin-top: 4px; }

@font-face {
  font-family: 'topology';
  src: url("http://at.alicdn.com/t/font_1331132_h688rvffmbc.ttf") format("truetype"); }

@font-face {
  font-family: 'ltee';
  src: url("http://at.alicdn.com/t/font_2052340_ceohsubmal7.ttf") format("truetype"); }

@font-face {
  font-family: 'ltdx';
  src: url("http://at.alicdn.com/t/font_2073009_5ilnjxypv6l.ttf") format("truetype"); }

body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif; }

label {
  font-weight: 700; }

html {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

#app {
  height: 100%; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

.no-padding {
  padding: 0px !important; }

.padding-content {
  padding: 4px 0; }

a:focus,
a:active {
  outline: none; }

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none; }

div:focus {
  outline: none; }

.fr {
  float: right; }

.fl {
  float: left; }

.pr-5 {
  padding-right: 5px; }

.pl-5 {
  padding-left: 5px; }

.block {
  display: block; }

.pointer {
  cursor: pointer; }

.inlineBlock {
  display: block; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

aside {
  background: #eef1f6;
  padding: 8px 24px;
  margin-bottom: 20px;
  border-radius: 2px;
  display: block;
  line-height: 32px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #2c3e50;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  aside a {
    color: #337ab7;
    cursor: pointer; }
    aside a:hover {
      color: #20a0ff; }

.app-container {
  padding: 20px; }

.components-container {
  margin: 30px 50px;
  position: relative; }

.pagination-container {
  margin-top: 30px; }

.text-center {
  text-align: center; }

.sub-navbar {
  height: 50px;
  line-height: 50px;
  position: relative;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  -webkit-transition: 600ms ease position;
  transition: 600ms ease position;
  background: -webkit-gradient(linear, left top, right top, from(#20b6f9), color-stop(0%, #20b6f9), color-stop(100%, #2178f1), to(#2178f1));
  background: linear-gradient(90deg, #20b6f9 0%, #20b6f9 0%, #2178f1 100%, #2178f1 100%); }
  .sub-navbar .subtitle {
    font-size: 20px;
    color: #fff; }
  .sub-navbar.draft {
    background: #d0d0d0; }
  .sub-navbar.deleted {
    background: #d0d0d0; }

.link-type,
.link-type:focus {
  color: #337ab7;
  cursor: pointer; }
  .link-type:hover,
  .link-type:focus:hover {
    color: #20a0ff; }

.filter-container {
  padding-bottom: 10px; }
  .filter-container .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px; }

.multiselect {
  line-height: 16px; }

.multiselect--active {
  z-index: 1000 !important; }

.form-item-helper {
  font-size: 12px;
  line-height: 14px;
  color: #999; }

fieldset {
  border: 1px solid #DCDFE6;
  margin-bottom: 24px; }
  fieldset legend {
    font-size: 16px;
    color: #606266; }
