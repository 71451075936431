.navbar[data-v-d16d6306] {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
          box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.navbar .hamburger-container[data-v-d16d6306] {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    -webkit-transition: background .3s;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;
}
.navbar .hamburger-container[data-v-d16d6306]:hover {
      background: rgba(0, 0, 0, 0.025);
}
.navbar .breadcrumb-container[data-v-d16d6306] {
    float: left;
}
.navbar .errLog-container[data-v-d16d6306] {
    display: inline-block;
    vertical-align: top;
}
.navbar .right-menu[data-v-d16d6306] {
    float: right;
    height: 100%;
    line-height: 50px;
}
.navbar .right-menu .role[data-v-d16d6306] {
      font-size: 12px;
      color: #666;
      border: 1px solid #999;
      padding: 2px 4px;
      margin-right: 12px;
      border-radius: 2px;
}
.navbar .right-menu .avatar-container[data-v-d16d6306] {
      margin-right: 30px;
}
.navbar .right-menu .avatar-container .avatar-wrapper[data-v-d16d6306] {
        margin-top: 5px;
        position: relative;
}
.navbar .right-menu .avatar-container .avatar-wrapper .user-name[data-v-d16d6306] {
          font-size: 14px;
          cursor: pointer;
}
.navbar .right-menu .avatar-container .avatar-wrapper .user-avatar[data-v-d16d6306] {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
}
.navbar .right-menu .avatar-container .avatar-wrapper .el-icon-caret-bottom[data-v-d16d6306] {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
}
