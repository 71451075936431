.sidebarLogoFade-enter-active[data-v-6494804b] {
  -webkit-transition: opacity 1.5s;
  transition: opacity 1.5s;
}
.sidebarLogoFade-enter[data-v-6494804b],
.sidebarLogoFade-leave-to[data-v-6494804b] {
  opacity: 0;
}
.sidebar-logo-container[data-v-6494804b] {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;
}
.sidebar-logo-container .sidebar-logo-link[data-v-6494804b] {
    height: 100%;
    width: 100%;
}
.sidebar-logo-container .sidebar-logo-link .sidebar-logo[data-v-6494804b] {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
}
.sidebar-logo-container .sidebar-logo-link .sidebar-title[data-v-6494804b] {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
}
.sidebar-logo-container.collapse .sidebar-logo[data-v-6494804b] {
    margin-right: 0px;
}
