.app-main[data-v-8d902f04] {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}
.fixed-header + .app-main[data-v-8d902f04] {
  padding: 88px 24px 0;
}
