.app-breadcrumb.el-breadcrumb[data-v-b50ef614] {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;
}
.app-breadcrumb.el-breadcrumb .no-redirect[data-v-b50ef614] {
    color: #97a8be;
    cursor: text;
}
.app-breadcrumb.el-breadcrumb a[data-v-b50ef614] {
    text-decoration: none;
}
